<template>
    <div class="product">
        <div class="product-head">
            <div class="product-title">产品详情</div>
        </div>
        <div style="margin-top: 40px">
            <el-row style="text-align: center;font-size: 26px;font-weight: 600">数字病理切片扫描仪</el-row>
            <el-row class="product-img">
                <img src="@/../static/iamges/chanpin.png">
            </el-row>
        </div>
        <div class="parameter">
            <div class="parameter-title">技术规格</div>
            <el-table
                    :data="tableData"
                    stripe
                    border
            >
                <el-table-column
                        prop="performance"
                        label="性能指标"
                        align="center"
                        width="140px"

                >
                </el-table-column>
                <el-table-column
                        prop="parameter"
                        label="参数"
                        align="center">
                </el-table-column>
            </el-table>
        </div>

        <div class="model" >
            <el-row>
                <el-col class="model-title" :lg="{span:8,offset:4}">AI建模应用步骤</el-col>
                <el-col class="model-title2" :lg="{span:8,offset:0}">AI MODELING APPLICATIONS STEPS</el-col>
            </el-row>
            <el-row class="ai-liucheng">
                <img class="liucheng" src="@/../static/iamges/liucheng.jpg">
            </el-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "productDetails",
        data() {
            return {
                tableData: [{
                    performance: '装载片数',
                    parameter: '1片'
                }, {
                    performance: '切片规格',
                    parameter: '26mm*76mm，厚度0.8mm到1.2mm（含盖玻片）'


                }, {
                    performance: '核心成像相机',
                    parameter: 'usb3.0 160万像素全局曝光彩色面阵相机'


                }, {
                    performance: '分辨率',
                    parameter: '60X 0.25um/像素'


                },
                    {
                        performance: '扫描方式',
                        parameter: '面阵扫描'


                    },
                    {
                        performance: '扫描区域尺寸',
                        parameter: '≥24mm*50mm'


                    },
                    {
                        performance: '扫描速度',
                        parameter: '15mmX15mm ＜30S'


                    },
                    {
                        performance: '聚焦方式',
                        parameter: '自动聚焦'


                    },
                    {
                        performance: '多层扫描',
                        parameter: '可对整张切片进行多层扫描，自行设置切片扫描层数'


                    },
                    {
                        performance: '扫描输出',
                        parameter: '24—bit，即时预览'


                    },
                    {
                        performance: '尺寸',
                        parameter: '130mmX110mmX130mm'


                    }, {
                        performance: '重量',
                        parameter: '2.3kg'


                    }, {
                        performance: '电源电压',
                        parameter: '220V'


                    }, {
                        performance: '功率',
                        parameter: '约70W'


                    }, {
                        performance: '工作温度',
                        parameter: '5度到40度'
                    },

                ]
            }
        },
        methods: {

        },
        mounted() {
            this.$emit("3", ["1", "3"])
        }

    }
</script>

<style scoped lang="css">

    .product-head {
        width: 100%;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.37);
    }

    .product-title {
        font-size: 30px;
        font-weight: 600;
        color: #074A81;
        text-align: center;
        line-height: 90px;

    }
    .product-img img {
        width: 250px;
        height: 340px;
    }
    .parameter{
        background-color:#F4F4F4;
        padding-top: 50px;
        padding-bottom: 100px;

    }
    .parameter-title{
        font-size: 26px;
        font-weight: 600;
       margin-bottom: 40px;
    }
    .model{
        margin-top: 70px;
    }
    .model-title{
        font-size: 26px;
        font-weight: 600;
        color: #074A81;
    }
    .model-title2{
        font-size: 26px;
        color: #074A81;
    }
    >>>.el-table thead{
        font-size: 16px;
        color: black;
        font-weight: 600;
    }



    /*小于768px*/
    @media only screen and (max-width: 768px) {
        .el-table {
            width: 80vw;
            margin: auto;
        }
        .liucheng{
            width: 98vw;

        }
        .model-title2{
            font-size: 16px;
            color: #074A81;
        }
        .ai-liucheng{
            margin-top: 35px;
            margin-bottom:50px;
        }

    }


    /*769px-992px*/
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .el-table {
            width: 60vw;
            margin: auto;
        }
        .liucheng{
            width: 60vw;
        }
        .model-title2{
            font-size: 26px;
            color: #074A81;
        }
        .ai-liucheng{
           margin-top: 70px;
            margin-bottom: 60px;
        }
    }

    /*992px-1200px*/
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .el-table {
            width: 40vw;
            margin: auto;
        }
        .liucheng{
            width: 40vw;
        }
        .model-title2{
            font-size: 26px;
            color: #074A81;}

        .ai-liucheng{
            margin-top: 70px;
            margin-bottom: 60px;
        }
    }

    /*大于1200px*/
    @media only screen and (min-width: 1200px) {
        .el-table {
            width: 40vw;
            margin: auto;
        }
        .liucheng{
            width: 60vw;
        }
        .model-title2{
            font-size: 26px;
            color: #074A81;
        }
        .ai-liucheng{
            margin-top: 70px;
            margin-bottom: 60px;
        }
    }

</style>