import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import index from '../views/index/index.vue'
import AboutUs from '../views/index/aboutUs/AboutUs.vue'
import NewsInformation from '../views/index/newsInfomation/NewsInformation.vue'
import recruit from '../views/index/recruit/recruit.vue'
import Homepage from '../views/index/homePage/HomePage.vue'
import ProductDetails from '../views/index/productDetails/productDetails.vue'
import ScanList from '../views/index/scanList/scanList.vue'
import contactUs from '../views/index/contactUs/contactUs.vue'
import newsList from '../views/index/newsList/newsList.vue'


Vue.use(VueRouter);

const routes:Array<RouteConfig> = [
  {
    path: '/index',
    redirect:'/homepage'
  },
  {
    path: '/',
    redirect:'/homepage'
  },
  {
    path: '/index',
    name: 'index',
    meta:{
      title:'首页'
    },
    component:index,
    children: [
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component:AboutUs,
        meta:{
          title:"关于我们"
        },
      },
      {
        path: '/newsInformation',
        name: 'NewsInformation',
        component:NewsInformation
      },
      {
        path: '/newsList',
        name: 'newsList',
        component:newsList
      },
      {
        path: '/productDetails',
        name: 'ProductDetails',
        component:ProductDetails
      },
      {
        path: '/scanList',
        name: 'ScanList',
        component:ScanList
      },
      {
        path: '/recruit',
        name: 'recruit',
        component:recruit
      },
      {
        path: '/homepage',
        name: 'Homepage',
        component:Homepage
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component:contactUs
      },
    ]
  },

];

const router = new VueRouter({
  routes,
  mode:"hash"
});


export default router
