<template>
    <div class="contact">
        <div class="contact-head">
            <div class="zpTitle">热招职位</div>
        </div>
        <div class="zhaopin-detail">
            <div>
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">生物医学信息</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">硕士以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 70px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、从事生物医学大数据分析，从海量信息中发现生物学意义</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、特征提取，机器学习，模型搭建</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、实现生物医学规律发现，疾病预测，辅助诊疗等</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、硕士以上学历, 生物信息，计算机，统计相关专业优先</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、掌握至少一门编程语言</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、有团队合作精神, 对生物医学信息，系统生物学有浓厚兴趣</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、有想象力，好奇心，轻松阅读英文文献</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、有生物信息学实践经验和论文发表者优先。</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">生物医学编辑(兼职)</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">硕士以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 60px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、阅读最新生物医学英文文献</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、撰写生物医学前沿文档</el-col>
                                </el-row>

                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、生物医学相关专业, 重点院校硕士及以上学历</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有团队合作精神, 对生物医学信息和人工智能有浓厚兴趣</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、有想象力，好奇心，轻松阅读英文文献</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、有临床背景者优先，有实验室工作经历者优先</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">Python工程师</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">硕士以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 70px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、对数据敏感，挖掘有效生物医疗相关的数据</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、协助人工智能工程师，提供可靠健壮的数据</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、对爬虫系统做系统性的功能升级和补充</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有良好的数据结构和算法基础</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、熟悉常用的数据库如：oracle、mysql、redis</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、熟悉Python</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、有爬虫经验优先，使用过scrapy高并发爬虫优先</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">C++开发工程师</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">本科以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 80px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、生物医学影像数据分析功能模块开发</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、嵌入式硬件控制模块开发</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、机器学习，迁移学习商业化部署</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有良好的数据结构和算法基础</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、熟悉VC++开发环境</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、熟悉常用的数据库如：oracle、mysql、redis</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、参与过大型C++项目优先。有分布式或多线程编程经验优先</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>6、有想象力，好奇心，可以阅读英文文献</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">前端开发工程师</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">本科以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 80px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、负责可视化平台及组件的框架设计和研发，配合交互视觉，完成设计稿高还原度的实现</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、开发以业务分析为主的数据产品和展示为主的大屏项目，负责项目开发中重点、难点技术攻坚</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、参与前端标准化，探索前端新技术并落地实践</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、本科及以上学历，计算机相关专业</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有扎实的编程能力和抽象能力，有优秀的设计和代码品位，热爱编程</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、有良好的数据结构和算法基础</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、熟悉javascript，html5，jQuery。对前端图形库有足够的了解，知道前端图形绘制的各项技术方案</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、熟练运用layui，有微信手机页面开发经验的，可作为加分项</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">装配、检验员</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">大专以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 140px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、医疗器械产品装配</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、根据检验标准，完成进料检验工作</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、根据检验标准，完成生产制程、成品及出货检验工作</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、如实记录检验结果，提交检验报表</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、不良品（原材料、半成品及成品）的反馈及处理</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>6、监督及检查仓库、生产车间现场的5S及GMP管理要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>7、协助处理客户投诉的调查分析，及纠正改进</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、电子或计算机等相关理工科专业，大专以上学历</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有1-2年装配、检验员工作经历，医疗器械电子产品优先</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、有独立完成进料、制成、成品及出货检验的任一相关经历</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、有质量报表的记录相关经验</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、熟练使用基本统计报表办公软件，如Excel</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>6、数量操作基本检验工具、检测设备等</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

            <div style="margin-top: 30px;padding-bottom: 80px">
                <el-card class="card-box" shadow="always" style="background-color: #5D9AF4;color: white">
                    <el-row style="display: flex;align-items: center;">
                        <el-col>
                            <img src="@/../static/iamges/zhaopin.png" style="width: 45px">
                        </el-col>
                        <el-col>工作地点</el-col>
                        <el-col>学历要求</el-col>
                    </el-row>
                    <el-row style="display: flex;align-items: center;">
                        <el-col style="font-size: 14px">医疗器械销售</el-col>
                        <el-col style="font-size: 14px">苏州</el-col>
                        <el-col style="font-size: 14px">专科以上</el-col>
                    </el-row>

                </el-card>
                <el-card shadow="always">
                    <el-row>
                        <el-col class="hidden-md-and-down" :span="5" :offset="1" style="margin-top: 100px">如有意向<br>请将您的简历发送至<br>hr@medppp.com
                        </el-col>
                        <el-col :span="14" :offset="4" style="text-align: left">
                            <el-row>
                                <el-row>
                                    <el-col>岗位职责</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、熟悉产品知识，根据市场需求，对区域内市场客户提供产品介绍、培训及咨询</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、管理客户关系，及时收集、反馈、解决产品推广过程中的各种意见和建议</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、负责区域内客户的定期维护拜访、市场推广及产品的宣传活动</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、规划并完善行业目标客户群的拓展策划，完成预期销售目标</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、主要负责区域：全国</el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-row style="margin-top: 20px">
                                    <el-col>应聘要求</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>1、专科及以上学历，市场营销或医学相关专业优先</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>2、有三年以上医疗器械销售工作经验</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>3、性格外向、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>4、具备一定的市场分析及判断能力，良好的客户服务意识</el-col>
                                </el-row>
                                <el-row style="font-size: 14px;padding: 4px 0px">
                                    <el-col>5、有责任心，能承受较大的工作压力</el-col>
                                </el-row>
                            </el-row>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="hidden-lg-and-up" style="margin-top: 10px">如有意向请将您的简历发送至hr@medppp.com</el-col>
                    </el-row>
                </el-card>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "recruit",
        mounted() {
            this.$emit("handleSelect", "5", ["5"])
        }
    }
</script>

<style scoped>
    .contact {
    }

    .contact-head {
        width: 100%;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.37);
    }

    .zpTitle {
        font-size: 32px;
        font-weight: 600;
        color: #074A81;
        text-align: center;
        line-height: 90px;

    }

    /*小于992px*/
    @media only screen and (max-width: 992px) {
        .zhaopin-detail {
            width: 90vw;
            margin: auto;
            margin-top: 60px
        }

    }

    /*大于992px*/
    @media only screen and (min-width: 992px) {
        .zhaopin-detail {
            width: 60vw;
            margin: auto;
            margin-top: 60px
        }
    }


</style>