<template>
  <div class="index">
    <el-container>
      <!--            头部导航-->
      <el-header>
        <!--                头部导航，宽度>992的时候展示-->
        <el-row class="hidden-sm-and-down">
          <!--                    <el-col :span="8" :offset="4" class="header-title">DeepMega</el-col>-->
          <el-col :lg="{span:8,offset:4}" :md="{span:12,offset:0}" :sm="{span:12,offset:0}"
                  class="header-title">DeepMega
          </el-col>
          <el-col class="menu" :lg="{span:8,offset:4}" :md="{span:12,offset:0}" :sm="{span:12,offset:0}">
            <el-menu ref="menu"
                     :default-active='$router.currentRoute.path'
                     class="el-menu-demo" mode="horizontal" router
                     @select="handleSelect" text-color="#000000" active-text-color="#fff">

              <el-menu-item route="/homepage" :style="menuItemStyle[0]" index="0">首页</el-menu-item>
              <!--                            <el-menu-item route="/aboutUs" index="3" :style="menuItemStyle[3]">关于我们</el-menu-item>-->


<!--              <el-submenu ref="submenu" index="1" :style="menuItemStyle[1]"-->
<!--                          style="font-size: 18px">-->
<!--                <template slot="title">-->
<!--                  <span :style="menuItemStyle[1]" class="submenu_title">产品中心</span>-->
<!--                </template>-->
<!--                <el-menu-item route="/scanList" index="2" :style="menuItemStyle[2]">扫描列表</el-menu-item>-->
<!--                &lt;!&ndash;                                <el-menu-item route="/productDetails" index="3" :style="menuItemStyle[3]">产品详情&ndash;&gt;-->
<!--                &lt;!&ndash;                                </el-menu-item>&ndash;&gt;-->
<!--              </el-submenu>-->

              <!--                            <el-menu-item route="/" :style="menuItemStyle[1]"  index="2">关于我们</el-menu-item>-->
              <el-menu-item route="/newsList" :style="menuItemStyle[4]" index="4">新闻动态
              </el-menu-item>
              <el-menu-item route="/recruit" :style="menuItemStyle[5]" index="5">人才招聘</el-menu-item>
              <el-menu-item route="/contactUs" :style="menuItemStyle[6]" index="6">联系我们</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>

        <!--                头部导航，宽度<992的时候展示-->
        <el-row class="hidden-md-and-up" style="display: flex;align-items: center;">
          <el-col :span="3" :offset="0" class="header-title">DeepMega</el-col>
          <el-col :span="2" :offset="18">

            <!--                        点击图标，显示抽屉-->
            <el-button @click="drawer = true" type="text" style="display: flex;align-items: center;">
              <i class="el-icon-menu" style="font-size: 30px;color: #282828"/>
            </el-button>
          </el-col>
        </el-row>
        <!--                头部抽屉导航，宽度<992的时候展示-->
        <el-drawer
            :visible.sync="drawer"
            direction="rtl"
            :with-header="true"
            size="90%">
          <template slot="title">

          </template>
          <span>
                          <el-menu ref="mobileMenu"
                                   :default-active='$router.currentRoute.path'
                                   router
                                   @select="handleSelect" text-color="#000000" active-text-color="#fff">
                               <el-menu-item route="/homepage" :style="menuItemStyle[0]" index="0">首页</el-menu-item>
                            <!--           无效区域                 -->
                            <!--                               <el-submenu ref="submenu" index="1" :style="menuItemStyle[1]"-->
                            <!--                                           style="font-size: 18px">-->
                            <!--                                <template slot="title">-->
                            <!--                                    <span :style="menuItemStyle[1]" class="submenu_title">产品中心</span>-->
                            <!--                                </template>-->

                            <!--                                <el-menu-item route="/scanList" :style="menuItemStyle[2]" index="2">扫描列表</el-menu-item>-->
                            <!--&lt;!&ndash;                               <el-menu-item route="/productDetails" :style="menuItemStyle[3]"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                             index="3">产品详情</el-menu-item>&ndash;&gt;-->
                            <!--                               </el-submenu>-->

                            <!--                    <el-menu-item route="/aboutUs" index="3" :style="menuItemStyle[3]">关于我们</el-menu-item>-->
                               <el-menu-item route="/newsList" :style="menuItemStyle[4]"
                                             index="4">新闻动态</el-menu-item>
                              <el-menu-item route="/recruit" :style="menuItemStyle[5]" index="5">人才招聘</el-menu-item>
                              <el-menu-item route="/contactUs" :style="menuItemStyle[6]"
                                            index="6">联系我们</el-menu-item>
                       </el-menu>
                    </span>
        </el-drawer>
      </el-header>


      <!--            中间部分，按照路由展示-->

      <el-main style="height: 100%;width: 100%;">
        <router-view v-on:handleSelect="handleSelect"/>
      </el-main>


      <!--            底部-->
      <div class="footer">版权所有:苏州迪美格智能科技有限公司 &nbsp;<span><a href="http://beian.miit.gov.cn"
                                                                             target="_blank"
                                                                             rel="nofollow"
                                                                             style="color: white;white-space: nowrap">苏ICP备17054065号-4</a></span>
      </div>


    </el-container>

  </div>
</template>
<script>

export default {
  name: "index",
  data() {
    return {
      activeIndex: '1',

      // 抽屉默认关闭
      drawer: false,

      // 选中的导航的背景颜色，字体颜色
      menuSeleted: {backgroundColor: "#074A81!important", color: "#ffffff!important"},

      // 未选中的导航背景颜色，字体颜色
      menuUnSeleted: {backgroundColor: "#ffffff!important", color: "#000000!important"},

      //选中的导航的样式=选中的导航背景颜色和字体颜色
      menuItemStyle: [
        this.menuSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
      ]
    }
  },
  methods: {
    //父级路由接收子级路由传递过来的索引，通过页面route根据子路由传递过来的索引值配置选中导航的背景颜色
    // 字体颜色，进行页面跳转
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      //
      // if (keyPath[keyPath.length-1] === '0') {
      //     this.$router.push({
      //         path: "/homepage"
      //     })
      // }
      // if (keyPath[keyPath.length-1] === '2') {
      //     this.$router.push({
      //         path: "/scanList"
      //     })
      // }
      // if (keyPath[keyPath.length-1] === '3') {
      //     this.$router.push({
      //         path: "/productDetails"
      //     })
      // }
      // if (keyPath[keyPath.length-1] === "4") {
      //     this.$router.push({
      //         path: "/newsInformation"
      //     })
      // }
      // if (keyPath[keyPath.length-1] === "5") {
      //     this.$router.push({
      //         path: "/recruit"
      //     })
      // }
      // if (keyPath[keyPath.length-1] === "6") {
      //     this.$router.push({
      //         path: "/contactUs"
      //     })
      // }

      //先将所有导航的背景颜色和字体颜色都设为未选中时候的样式
      this.menuItemStyle = [
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
        this.menuUnSeleted,
      ];
      // console.log(keyPath);

      // 改变选中导航的背景颜色和字体颜色
      this.menuItemStyle[keyPath[0]] = this.menuSeleted;
      this.menuItemStyle[keyPath[1]] = this.menuSeleted;
      //抽屉状态设为关闭
      this.drawer = false
    }
  },
  mounted() {
    this.$root.$index = this;
    console.log(this)
  }
}
</script>

<style scoped lang="css">

/*小于992*/
@media only screen and (max-width: 992px) {


  .btn-group {
    flex-direction: column !important;
  }

  .footer {
    font-size: 13px;

  }

  .el-main {
    margin-top: 70px;
  }
}

/*大于992*/
@media only screen and (min-width: 992px) {

  .btn-group {
    flex-direction: row !important;
  }

  .el-main {
    margin-top: 100px;
  }
}

.el-header {
  color: #333;
  line-height: 60px;
  padding-top: 30px;

}


.header-title {
  font-size: 40px;
  font-weight: 800;
}

.el-main {
  text-align: center;
  height: 100vh;
  padding: 0;
  margin-bottom: 50px;

}

.el-menu .el-menu-item {
  font-size: 18px;
}

.el-menu .submenu_title {
  font-size: 18px !important;
}

.el-submenu /deep/ .el-submenu__title:hover {
  color: #000000 !important;
  background-color: #ffffff !important;
}


.el-menu {
  font-size: 18px;
}


.footer {
  display: flex;
  justify-content: center;
  /*background-color: rgb(5, 36, 51);*/
  background-color: #323437;
  /*width: 100%;*/
  height: 50px;
  line-height: 50px;
  /*color: #9d9d9d;*/
  color: white;
  font-weight: 500;

}

/*    992-1200之间*/
@media screen and (min-width: 992px)and(max-width: 1200px) {

}

/*    768-992之间*/
@media screen and (min-width: 768px)and(max-width: 992px) {

}

/*    小于768*/
@media only screen and (max-width: 768px) {
  .header-title {
    font-size: 40px;
    font-weight: 800;

  }

  .el-main {
    margin-top: 60px;
  }
}

.footer {
  position: fixed;
  bottom: 0%;
  width: 100vw;
}


</style>
