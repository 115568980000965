<template>
    <div class="newList" style="padding-bottom: 40px">
        <el-card class="hidden-xs-only" v-for="(item,index1) in list" :key="index1+'A'">
            <el-row>
                <el-col class="newList-left" :lg="{span:4,offset:1}">
                    <img :src=item.image>
                </el-col>
                <el-col class="newList-right" :lg="{span:14,offset:5}" @click.native="gotoDetails(item.id)">
                    <el-row class="right-title">{{item.title}}</el-row>
                    <el-row class="right-text">{{item.text}}</el-row>
                    <el-row class="right-time">
                        <el-col :lg="{span:4,offset:18}">{{item.time}}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>


        <el-card class="hidden-sm-and-up" v-for="(item,index2) in list" :key="index2+'B'">
            <el-row>
                <el-col class="newList-left">
                    <img :src=item.image>
                </el-col>
                <el-col class="newList-right">
                    <el-row class="right-title" style="text-align: center;margin-top: 10px">{{item.title}}</el-row>
                    <el-row style="display: flex;margin-top: 10px">
                        <el-col style="font-weight: 600;color: #08487A;font-size: 15px" @click.native="gotoDetails(item.id)">
                            阅读全文
                        </el-col>
                        <el-col style="color: #08487A;font-size: 15px">{{item.time}}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {request} from "@/../src/utils/utils";

    export default {
        name: "newsList",
        data() {
            return {

                // list: [

                // ]
                list: [
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"


                    },
                    {
                        url: require("@/../static/iamges/news/n2.png"),
                        title: "图灵基因三岁啦",
                        text: "截至2022年底,我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发",
                        time: "2022-02-08"
                    },
                ],

            }
        },
        methods: {
            gotoDetails(id) {
                // console.log(111111);
                console.log(id);
                this.$router.push({
                    path: "/NewsInformation"
                })
            }
        },
        created() {
            request("get", "/v1/ow/getNewsInfo", null).then(res => {
                console.log(res);
                this.list = res;


            })
        }
    }
</script>

<style scoped lang="css">
    .el-card:hover{
        /*transition:0.5s  all cubic-bezier(1,0,0,1);*/
        transform: translateX(-30px);
        background-color: #F2F9FF;

    }
    .newList-left img {
        width: 300px;
        border-radius: 5px;
        margin-top: 10px;
    }

    .right-title {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
    }


    /*小于768px*/
    @media only screen and (max-width: 768px) {

        .el-card {
            margin-top: 10px;


        }

        .newList-left img {
            width: 200px;
            border-radius: 5px;
            margin-top: 10px;
        }

        .right-title {

            font-size: 20px;
            font-weight: 600;
        }


    }

    /*769px-992px*/
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .el-card {
            width: 60vw;
            margin: auto;
            margin-top: 40px;
        }

        .right-text {
            padding-left: 10px;
        }

        .right-text {
            text-align: left;
            margin-top: 22px;
            line-height: 38px;
            margin-left: -15px;

        }

        .right-time {
            margin-top: 30px;
            font-weight: 600;
            color: #08487A;
            /*font-size: 18px;*/
        }

        .newList-right {
            margin-top: 30px;
        }

    }

    /*992px-1200px*/
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .el-card {
            width: 60vw;
            margin: auto;
            margin-top: 40px;
        }

        .right-text {
            padding-left: 10px;
        }

        .right-text {
            text-align: left;
            margin-top: 22px;
            line-height: 38px;
            margin-left: -15px;

        }

        .right-time {
            margin-top: 30px;
            font-weight: 600;
            color: #08487A;
            /*font-size: 18px;*/
        }

        .newList-right {
            margin-top: 30px;
        }
    }

    /*1200px-1500px*/
    @media only screen and (min-width: 1200px)and (max-width: 1500px) {
        .el-card {
            width: 80vw;
            margin: auto;
            margin-top: 40px;
        }

        .right-text {
            text-align: left;
            margin-top: 22px;
            line-height: 38px;
            margin-left: -15px;

        }

        .right-time {
            margin-top: 30px;
            font-weight: 600;
            color: #08487A;
            /*font-size: 18px;*/
        }

        .newList-right {
            margin-top: 30px;
        }
    }

    /*大于1500*/
    @media only screen and (min-width: 1500px) {
        .el-card {
            width: 60vw;
            margin: auto;
            margin-top: 40px;
        }

        .right-text {
            text-align: left;
            margin-top: 22px;
            line-height: 38px;
            margin-left: -15px;

        }

        .right-time {
            margin-top: 30px;
            font-weight: 600;
            color: #08487A;
            /*font-size: 18px;*/
        }

        .newList-right {
            margin-top: 30px;
        }
    }


</style>