<template>
    <div id="app" >
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        name: "App",
        data() {
            return {

            }
        },
        mounted() {
        }
    }
</script>
<style lang="less">
    body{
      padding: 0;
      margin: 0;
    }
   #app{
     width: 100%;
     margin: 0;
     padding: 0;
   }

</style>
