<template>
    <div class="homePage">
<!--        轮播图-->
        <el-carousel>
            <el-carousel-item v-for="(url,index) in pictureList" :key="index">
                <el-image
                        class="carousel-img"
                        :src="url"
                        fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>


        <!--        <div class="homePage-chanpin" style="margin-top: 50px">-->
        <!--            <div class="chanpin-picture">-->
        <!--                <el-row class="chanpin-picture-title">-->
        <!--                    <el-col :span="4" :offset="10">公司产品</el-col>-->
        <!--                </el-row>-->
        <!--                <el-row :gutter="12" style="margin-top: 30px">-->
        <!--                    <el-col :span="8">-->
        <!--                        <el-card shadow="hover">-->
        <!--                            <img src="@/../static/iamges/jiqi1.jpeg">-->
        <!--                        </el-card>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="8">-->
        <!--                        <el-card shadow="hover">-->
        <!--                            <img src="@/../static/iamges/jiqi2.jpg">-->
        <!--                        </el-card>-->
        <!--                    </el-col>-->
        <!--                    <el-col :span="8">-->
        <!--                        <el-card shadow="hover">-->
        <!--                            <img src="@/../static/iamges/jiqi3.jpg">-->
        <!--                        </el-card>-->
        <!--                    </el-col>-->
        <!--                </el-row>-->
        <!--            </div>-->
        <!--        </div>-->
<!--产品特点介绍-->
        <div class="chanpin-tedian">
            <el-row class="chanpin-tedian-title">
                <el-col :lg="{span:4,offset:10}" :md="{span:24,offset: 0}">数字病理切片扫描仪TB-1</el-col>
            </el-row>
            <el-row class="chanpin-tedian-detail">
                <el-col :lg="{span:4,offset:5}" :md="{span:24,offset:0}">
                    <img src="@/../static/iamges/chanpin.png">
                </el-col>
                <el-col class="detail-text" :lg="{span:12,offset:3}" :md="{span:24,offset:0}">
                    <el-row style="margin-top: 15px">体积小、重量轻，方便携带</el-row>
                    <el-row style="margin-top: 15px">速度快，最快30S完成15mm*15mm区域扫描</el-row>
                    <el-row style="margin-top: 15px">自带AI建模系统，可细胞分类标注、数阳性细胞<br>识别特殊组织、远程标注模型解释、生成严谨美观的论文配图</el-row>
                    <el-row style="margin-top: 15px">智能远程拼接专利技术，可用于远程诊断服务</el-row>
                    <el-row style="margin-top: 20px">
<!--                        <el-button type="primary" @click="gotoDetail">产品详情</el-button>-->
                    </el-row>
                </el-col>
            </el-row>
        </div>


<!--关于我们-->
        <div class="aboutUs">
            <div class="aboutUs-head">
                <div class="aboutUs-head-text">公司介绍</div>
            </div>
            <div>
                <div class="aboutUs-detail">
                    <p>
                        苏州迪美格智能科技有限公司是一家国家级高新技术企业，苏州工业园区科技领军单位，2021年入选首批工信部与国家卫健委联合发布的“5G+医疗健康应用试点项目”。
                    </p>
                    <p>
                        公司拥有自主研发的病理切片自动扫描设备、高性能计算服务器集群、生物信息分析中心和光学病理实验室等设施，专注癌症病理大数据分析，通过AI病理检测定位、肿瘤病理亚型分类以及肿瘤病理预后预测等关键技术的不断突破，致力于成为病理AI前沿解决方案的提供商。
                    </p>
                    <p>
                        创始人任思远本科毕业于上海交通大学生物技术专业，研究生先后师从中科院癌症机理专家陈正军教授，和欧洲科学院院士著名生物医学信息专家Zoran
                        Obradovic;在生物医学信息领域多项一作论文得到包括TonyHunter在内8位欧美科学院院士的引述，并受邀成为多家国际期刊评审，2015年受邀撰写斯普林格出版社出版生物医学信息专著。
                    </p>


                </div>
            </div>

            <div class="gongsilicheng" style="margin-top: 30px">


                <!--            <div style="overflow: hidden;">-->
                <div style="font-size: 32px;color: #08487A;font-weight: 600">发展历程</div>
                <span>
                <img class="lichengPc" src="@/../static/iamges/licheng.png">
            </span>
                <!--                <div class="items">-->
                <!--                <span style="margin-bottom: -23px;margin-right: -30px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2016年<br/>5月司正式成立<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: 27px;margin-right: -35px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2017年<br/>7月完成天使轮融资<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: -19px;margin-right: -103px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2018年<br/>入选亚杰摇篮计划<br/>入选姑苏科技创业天使计划<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: 48px;margin-right: -120px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2019年<br/>入选国家级高新技术企业<br/>与中国细胞生物学协助建立战略合作伙伴关系<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: -48px;margin-right: -89px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2020年<br/>入选苏州园区科技领军人才项目<br/>参与中科院肿瘤体外检测项目<br/>参与中国疫控中心艾滋病耐药检测项目<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: 46px;margin-right: -77px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2021年12月<br/>完成A轮融资<br/>第一代数字病理切片扫描仪研发成功<br/>参与清华大学肿瘤新靶标筛选<br/>参与苏大一附院前列腺肿瘤亚型分类<br/>入选首批工信部与国家卫健委<br/>联合发布的”5G+医疗健康应用试点项目”<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->
                <!--                    <span style="margin-bottom: -38px;margin-right: 0px" class="item">-->
                <!--                    <span class="text">-->
                <!--                        2022年12月<br/>第二代数字病理切片扫描仪<br/>通过检测标准：GB/T18268.1.26<br/>-->
                <!--                    </span>-->
                <!--                    <div class="dot"></div>-->
                <!--                    <span class="line"></span>-->
                <!--                </span>-->

                <!--                </div>-->
                <!--            </div>-->
            </div>
            <!--        <div style="margin-top: -94px;">-->
            <!--            <img src="@/../static/iamges/mountain.png">-->
            <!--        </div>-->
            <div class="hidden-md-and-up" style="margin-top: 100px;height: 550px;margin-bottom: 40px">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="资质荣誉" name="资质荣誉">
                        <el-row>
                            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
                                &nbsp;首批工信部与国家卫健委联合发布的“5G+医疗健康”
                                <br/>
                                &nbsp;国家级高新技术企业
                                <br/>
                                &nbsp;江苏省民营科技企业
                                <br/>
                                &nbsp;苏州工业园区领军人才单位、亚杰摇篮计划学员单位
                                <br/>
                                &nbsp;...
                                <br/>

                            </el-col>
                            <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 20px">
                                <img style="width: 300px;" src="@/../static/iamges/5G.png">
                                <img style="width: 300px;" src="@/../static/iamges/gaoqi.jpg">
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="发明专利" name="发明专利">
                        <el-row>
                            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
                                &nbsp;基于强化学习的医学自然语言语义网络反馈式提取系统与方法
                                <br/>
                                &nbsp;基于模板编辑的分布式医学自然语言文本云处理系统
                                <br/>
                                &nbsp;数字切片扫描仪的移动对焦方法、扫描方法及装置
                                <br/>
                                &nbsp;...
                                <br/>

                            </el-col>
                            <!--                <el-col class="About-rongyu-right-md" :span="10">-->
                            <!--                    <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">-->
                            <!--                    <img style="width: 300px; margin-left: 10px"  src="@/../static/iamges/ruanzhu2.jpg">-->
                            <!--                </el-col>-->
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="软件著作权" name="软件著作权">
                        <el-row>
                            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
                                &nbsp;数字切片AI模型管理软件V1.0
                                <br/>
                                &nbsp;数字切片机器学习训练软件V1.0
                                <br/>
                                &nbsp;数字切片扫描AI控制系统（简称：扫描控制系统）V1.0
                                <br/>
                                &nbsp;...
                                <br/>

                            </el-col>
                            <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 20px">
                                <img style="width: 300px;" src="@/../static/iamges/ruanzhu1.jpg">
                                <img style="width: 300px;" src="@/../static/iamges/ruanzhu2.jpg">
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="实用新型专利" name="实用新型专利">
                        <el-row>
                            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
                                &nbsp;一种结构紧凑的病理切片扫描二维电动位移平台
                                <br/>
                                &nbsp;一种快速精确的显微镜对焦装置
                                <br/>
                                &nbsp;一种用于数字切片扫描的切片位移机构
                                <br/>
                                &nbsp;...
                                <br/>

                            </el-col>
                            <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 20px">
                                <img style="width: 300px;" src="@/../static/iamges/shiyong1.png">
                                <img style="width: 300px;" src="@/../static/iamges/shiyong2.png">
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="外观设计专利" name="外观设计专利">
                        <el-row>
                            <el-col class="About-rongyu-left-md" :span="22" :offset="1">
                                &nbsp;数字切片扫描仪（DM03）
                                <br/>
                                &nbsp;显微镜坐标识别板
                                <br/>

                            </el-col>
                            <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 20px">
                                <img style="width: 300px;" src="@/../static/iamges/waiguan1.png">
                                <img style="width: 300px;" src="@/../static/iamges/waiguan2.png">
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <div class="About-rongyu hidden-sm-and-down" style="margin-top: 100px;height: 550px;margin-bottom: 40px">
                <el-row>
                    <el-col :span="16" :offset="4">
                        <div class="btn-group">
                            <div class="btns" @mouseover="handleSelect(0)" :style="menuItemStyle[0]">资质荣誉</div>
                            <div class="btns" @mouseover="handleSelect(1)" :style="menuItemStyle[1]">发明专利</div>
                            <div class="btns" @mouseover="handleSelect(2)" :style="menuItemStyle[2]">软件著作权</div>
                            <div class="btns" @mouseover="handleSelect(3)" :style="menuItemStyle[3]">实用新型专利</div>
                            <div class="btns" @mouseover="handleSelect(4)" :style="menuItemStyle[4]">外观设计专利</div>
                        </div>


                    </el-col>
                </el-row>
                <el-row v-if="selectedKey===0">
                    <el-col class="About-rongyu-left" :span="8" :offset="4">
                        <!--                    <el-row class="left-title">资质荣誉4项</el-row>-->
                        <el-row class="left-text">
                            <el-col>
                                &nbsp;首批工信部与国家卫健委联合发布的“5G+医疗健康”
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="margin: 20px 0px">
                                &nbsp;国家级高新技术企业
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                &nbsp;江苏省民营科技企业
                            </el-col>
                            <el-col style="margin: 20px 0px">
                                &nbsp;苏州工业园区领军人才单位、亚杰摇篮计划学员单位
                            </el-col>
                            <el-col>
                                &nbsp;...
                            </el-col>

                        </el-row>
                    </el-col>
                    <el-col class="About-rongyu-right-md" :span="10" style="margin-top: 80px">
                        <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/5G.png">
                        <img style="width: 300px; margin-left: 10px" src="@/../static/iamges/gaoqi.jpg">
                    </el-col>
                </el-row>

                <el-row v-if="selectedKey===1">
                    <el-col class="About-rongyu-left" style="margin-top: 40px" :span="8" :offset="4">
                        <!--                    <el-row class="left-title">发明专利7项</el-row>-->
                        <el-row class="left-text">
                            <el-col>
                                &nbsp;基于强化学习的医学自然语言语义网络反馈式提取系统与方法
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="margin: 20px 0px">
                                &nbsp;基于模板编辑的分布式医学自然语言文本云处理系统
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                &nbsp;数字切片扫描仪的移动对焦方法、扫描方法及装置
                            </el-col>
                            <el-col style="margin: 20px 0px">
                                &nbsp;...
                            </el-col>
                        </el-row>
                    </el-col>
                    <!--                <el-col class="About-rongyu-right-md" :span="10">-->
                    <!--                    <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">-->
                    <!--                    <img style="width: 300px; margin-left: 10px"  src="@/../static/iamges/ruanzhu2.jpg">-->
                    <!--                </el-col>-->
                </el-row>

                <el-row v-if="selectedKey===2">
                    <el-col class="About-rongyu-left" style="margin-top: 30px" :span="8" :offset="4">
                        <!--                    <el-row class="left-title">软件著作16项</el-row>-->
                        <el-row class="left-text">
                            <el-col>
                                &nbsp;数字切片AI模型管理软件V1.0
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="margin: 20px 0px">
                                &nbsp;数字切片机器学习训练软件V1.0
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                &nbsp;数字切片扫描AI控制系统（简称：扫描控制系统）V1.0
                            </el-col>
                            <el-col style="margin: 20px 0px">
                                ...
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col class="About-rongyu-right-md" style="margin-top: 30px" :span="10">
                        <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/ruanzhu1.jpg">
                        <img style="width: 300px; margin-left: 10px" src="@/../static/iamges/ruanzhu2.jpg">
                    </el-col>
                </el-row>

                <el-row v-if="selectedKey===3">
                    <el-col class="About-rongyu-left" :span="8" :offset="4">
                        <!--                    <el-row class="left-title">实用新型专利11项</el-row>-->
                        <el-row class="left-text">
                            <el-col>
                                &nbsp;一种结构紧凑的病理切片扫描二维电动位移平台
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="margin: 20px 0px">
                                &nbsp;一种快速精确的显微镜对焦装置
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                &nbsp;一种用于数字切片扫描的切片位移机构
                            </el-col>
                            <el-col style="margin: 20px 0px">
                                &nbsp;...
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col class="About-rongyu-right-md" style="margin-top: 30px" :span="10">
                        <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/shiyong1.png">
                        <img style="width: 300px; margin-left: 10px" src="@/../static/iamges/shiyong2.png">
                    </el-col>
                </el-row>

                <el-row v-if="selectedKey===4">
                    <el-col class="About-rongyu-left" :span="8" :offset="4">
                        <!--                    <el-row class="left-title">外观设计专利2项</el-row>-->
                        <el-row class="left-text">
                            <el-col>
                                &nbsp;数字切片扫描仪（DM03）
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="margin: 20px 0px">
                                &nbsp;显微镜坐标识别板
                            </el-col>
                        </el-row>
                        <!--                    <el-row>-->
                        <!--                        <el-col>-->
                        <!--                            03 &nbsp;占位占位占位占位占位占位占位占位占位占位占位占位-->
                        <!--                        </el-col>-->
                        <!--                    </el-row>-->
                    </el-col>
                    <el-col class="About-rongyu-right" :span="10">
                        <img style="width: 300px;margin-left: -10px" src="@/../static/iamges/waiguan1.png">
                        <img style="width: 300px; margin-left: 10px" src="@/../static/iamges/waiguan2.png">
                    </el-col>
                </el-row>
            </div>
        </div>


        <!--        <div class="contactUs">-->
        <!--            <el-row class="contactUs-title" style="margin-bottom: 50px">联系我们</el-row>-->
        <!--            <el-row class="contactUs-logo">-->
        <!--                <el-col :lg="{span:10,offset:2}" :md="{span:24,offset:0}">-->
        <!--                    <img src="@/../static/iamges/logo.png">-->
        <!--                </el-col>-->
        <!--                <el-col :lg="{span:12}" :md="{span:24}" :sm="{span:24}">-->
        <!--                    <el-row class="contactUs-detail" v-for="(item,index) in contactUs" :key="index">-->
        <!--                        <el-col  :lg="{span:2,offset:0}" :md="{span:2,offset:0}" :sm="{span:2,offset:6}" >-->
        <!--                            <img :src="item.icon">-->
        <!--                        </el-col >-->
        <!--                        <el-col :lg="{span:5}"   :md="{span:14}"  :sm="{span:14}" style="color: black;font-weight: 600;white-space: nowrap" >{{item.text}}</el-col>-->
        <!--                    </el-row>-->
        <!--                </el-col>-->
        <!--            </el-row>-->
        <!--        </div>-->


    </div>
</template>
<script>
    export default {
        name: "Homepage",
        mounted() {
            //向父级路由（index）传参，传递自己的索引
            this.$emit("handleSelect", "0", ["0"])
        },
        data() {
            return {
                // 轮播图循环遍历
                pictureList: [
                    // require("@/../static/iamges/1.png"),
                    require("@/../static/iamges/sample.png"),
                    require("@/../static/iamges/gs.jpg"),

                ],

                activeIndex: '1',
                menuItemStyle: [
                    {backgroundColor: "#074A81", color: "#fff"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"}
                ],

                // 选中的索引值
                selectedKey: 0,
                activeName: '资质荣誉'


            }
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
                this.selectedKey = key;
                console.log(this.selectedKey);
                this.menuItemStyle = [
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"},
                    {backgroundColor: "#E4E4E4", color: "#000"}
                ];
                this.menuItemStyle[key] = {backgroundColor: "#074A81", color: "#fff"}
            },
            gotoDetail() {
                //点击跳转至产品详情页面
                this.$root.$index.handleSelect("3", ["1", "3"]);
                this.$router.push({
                    path: "/productDetails"
                })
            }
        }
    }
</script>

<style scoped lang="css">

    /*小于992px*/
    @media only screen and (max-width: 992px) {
        .homePage {
            padding-top: 50px;
        }

        .el-carousel >>> .el-carousel__container {
            height: 200px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 200px !important;
        }

        .detail-text {
            text-align: center;
        }

        .chanpin-tedian-detail {
            text-align: center;
        }

        .contactUs-logo {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            /* align-content: center; */
            align-items: flex-start;
        }

        .contactUs-logo img {
            width: 100vw;
        }

        .contactUs-detail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /*text-align: left;*/
            padding-right: 2px;
            padding-left: 2px;

        }

        .contactUs-detail >>> .el-col-24 {
            width: 10%;
        }

        .lichengPc {
            width: 100vw;
            margin-top: 80px;
        }

        .aboutUs-detail {
            padding: 0px 50px;
        }

        .About-rongyu-right-md {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

        }

        .About-rongyu-right-md img {
            box-shadow: 5px 5px 10px -5px black;
        }
    }
 /*大于768px*/
    @media only screen and (min-width: 768px) {
        .el-carousel >>> .el-carousel__container {
            height: 400px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 400px !important;
        }

        .contactUs-logo img {
            width: 65vw;

        }

        .contactUs-detail {
            display: flex;
            align-items: center;
            /*margin-left: 150px;*/


        }
    }
 /*大于992px*/
    @media only screen and (min-width: 992px) {

        .el-carousel >>> .el-carousel__container {
            height: 500px;
        }

        .carousel-img >>> img {
            width: 100vw;
            height: 500px !important;
        }

        .detail-text {
            text-align: left;
            font-size: 20px;
            color: gray;
            margin-top: -38px
        }

        .chanpin-tedian-detail {
            margin-top: 30px;
            display: flex;
            align-items: center;
        }


        .contactUs-logo img {
            width: 350px;
            height: 230px;
        }

        .contactUs-logo {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .contactUs-detail {
            display: flex;
            align-items: center;
            text-align: left;
        }

        .lichengPc {
            width: 75%;
            margin-top: 80px
        }

        .aboutUs-detail {
            font-size: 18px;
            margin-top: 50px;
            color: gray;
            padding: 0px 242px;
            margin-bottom: 100px;

        }

        .About-rongyu-right-md {
            display: flex;
            justify-content: flex-start;
            margin-left: -25px;
        }

        .About-rongyu-right-md img {
            box-shadow: 5px 5px 10px -5px black;
        }

    }


    .homePage-chanpin .chanpin-picture img {
        width: 300px;
        height: 300px;
    }

    .chanpin-picture-title, .chanpin-tedian-title,  .contactUs-title {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #074A81;
    }

    .chanpin-tedian {
        margin-top: 60px;
        padding: 50px 0px;
        background-color: rgba(226, 226, 226, 0.3);
    }


    .chanpin-tedian-detail img {
        width: 290px;
        height: 380px;
    }


    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }


    .contactUs {
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 60px;
        background-color: rgba(172, 172, 172, 0.45);

    }

    .contactUs-detail img {
        margin-top: 5px;
        width: 30px;
        height: 30px;

    }

    .el-tabs >>> .el-tabs__nav-scroll {
        /*display: flex;*/
        /*justify-content: center;*/
    }

    .aboutUs {
        height: 100%;
        width: 100%;
        margin-top: 50px;

    }

    .aboutUs-head {
        width: 100%;
        height: 90px;
        margin-bottom: 50px;
        /*background-color: rgba(217, 217, 217, 0.37);*/

    }

    .aboutUs-head-text {
        font-size: 32px;
        font-weight: 600;
        color: #074A81;
        /*text-align: left;*/
        line-height: 90px;
        /*margin-left: 100px;*/
        text-align: center;
    }


    .About-rongyu-right {
        display: flex;
        justify-content: flex-start;
        margin-left: -25px;
    }

    .About-rongyu-right img {
        box-shadow: 5px 5px 10px -5px black;
    }

    .gongsilicheng {
        margin-top: 50px;
        /*height: 68vh;*/
        background-color: rgba(43, 112, 178, 0.03);
        padding-top: 60px;
        padding-bottom: 120px;
        /*background-color: #36B9ED;*/
        /*background-color: #4587ed;*/
        width: 100%;
        display: flex;
        /*align-items: center;*/
        justify-content: center;
        /*overflow: hidden;*/

        flex-direction: column;
    }


    .gongsilicheng .items {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
    }

    .gongsilicheng .item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .gongsilicheng .text {
        /*width: 10vw;*/
        color: #fff;
        font-size: 14px;
    }

    .gongsilicheng .dot {
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 10px;
    }

    .gongsilicheng .line {
        border: solid 1px white;
        height: 800px;
    }

    .About-rongyu el-menu-item {
        font-size: 30px;
    }

    .rongyu-detail {
        font-size: 23px;
        font-weight: 500;
        width: 230px;
        border: 1px solid rgba(244, 244, 244, 0.5)
    }

    .About-rongyu-left, .About-rongyu-right {
        text-align: left;
        margin-top: 30px;
    }

    .left-text {
        margin-top: 30px;
    }


    .btn-group {
        display: flex;
        justify-content: space-around;
    }

    .btns {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 23px;
        border: 1px solid rgba(247, 247, 247, 1);
    }

    p {
        white-space: normal;
        text-align: left;
        text-indent: 2em;
        margin: 10px auto;
        line-height: 35px;

    }


</style>