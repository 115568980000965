<template>
    <div>
        <div class="scanList">

            <div class="list-head">
                <div class="list-title">扫描列表</div>
            </div>

            <el-card class="box-card" shadow="always" v-for="(item,index) in scanList" :key="index">

                <a :href="'http://114.218.33.19:4020/slide/viewer/viewer.html?slideId='+item.viewId"
                   target="_blank">
                    <el-row style="display: flex;align-items: center;text-align: center">
                        <el-col :lg="{span:10,offset:0}" :md="{span:10,offset:0}" :sm="{span:10,offset:0}" :xs="{span:9,offset:0}" class="list-left">
                            <img class="thumb" :src="item.thumb">
                        </el-col>
                        <el-col :lg="{span:12,offset:0}" :md="{span:12,offset:0}" :sm="{span:12,offset:0}"
                                :xs="{span:11,offset:1}" class="list-right">
                            <el-row class="right-title" :lg="{span:8,offset:8}" :md="{span:8,offset:8}"
                                    :sm="{span:8,offset:8}" :xs="{span:8,offset:8}">{{item.slideName}}
                            </el-row>
                            <el-row class="right-text">
                                <el-col class="text-details" :lg="{span:9,offset:3}" :md="{span:8,offset:3}"
                                        :sm="{span:8,offset:3}" :xs="{span:8,offset:3}">{{item.disease}}
                                </el-col>
                                <el-col class="text-details" :lg="{span:12,offset:0}" :md="{span:12,offset:0}"
                                        :sm="{span:12,offset:0}" :xs="{span:12,offset:0}">{{item.organ}}
                                </el-col>
                            </el-row>
                            <el-row class="right-text">
                                <el-col class="text-details" :lg="{span:8,offset:3}" :md="{span:8,offset:3}"
                                        :sm="{span:8,offset:3}" :xs="{span:8,offset:3}">{{item.specimen}}
                                </el-col>
                                <el-col class="text-details1 " :lg="{span:12,offset:0}" :md="{span:12,offset:0}"
                                        :sm="{span:12,offset:0}" :xs="{span:12,offset:0}">{{item.study}}
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </a>
            </el-card>
        </div>
    </div>
</template>
<script>

    import {request} from "@/../src/utils/utils";

    export default {
        name: "scanList",
        data() {
            return {
                //扫描列表，接收接口返回的数据
                scanList: [],
                //接收返回的viewId
                viewId: null


                // scanList: [
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00001",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00002",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00003",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00004",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00005",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00006",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00007",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00008",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00009",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //     {
                //         thumb: require("@/../static/iamges/sample.png"),
                //         slideName: "切片00010",
                //         specimen: "xxxxxxxxxx",
                //         organ: "xxxxxxxxxx",
                //         study: "xxxxxxxxxx",
                //         disease: "xxxxxxxxxx"
                //
                //     },
                //
                // ]

            }


        },
        methods: {},

        mounted() {
            this.$emit("2", ["1", "2"])
        },
        created() {
            request("get", "/v1/ow/getPublicSlideInfo", null).then(res => {
                console.log(res);
                this.scanList = res;
                this.viewId = res.viewId
            })
        }
    }
</script>

<style scoped lang="css">
    .list-head {
        width: 100%;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.37);
    }

    .el-table tr {
        font-size: 16px;
    }

    .list-title {
        font-size: 32px;
        font-weight: 600;
        color: #074A81;
        text-align: center;
        line-height: 90px;
    }
    /*小于768px*/
    @media only screen and (max-width: 768px) {
        .box-card {
            width: 95vw;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .list-right {
            margin-top: -10px;
            margin-left: 30px;
        }


        .text-details, .text-details1 {
            font-size: 16px;
            overflow: hidden; /*溢出部分隐藏*/
            white-space: nowrap; /*文本不换行*/
            text-overflow: ellipsis; /*当对象内文本溢出时显示省略标记（...）*/
        }

        .thumb {
            height: 140px;
        }
    }

    /*769px-992px*/
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .box-card {
            width: 80vw;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 20px;
        }

        .list-right {
            margin-top: -15px;
        }

        .right-text {
            display: flex;
            margin-top: 20px;

        }
        .text-details {
            font-size: 16px;
        }
        .text-details, .text-details1 {
            font-size: 18px;
            overflow: hidden; /*溢出部分隐藏*/
            white-space: nowrap; /*文本不换行*/
            text-overflow: ellipsis; /*当对象内文本溢出时显示省略标记（...）*/
        }
        .thumb {
            height: 200px;
        }
    }


    /*992px-1200px*/
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        .box-card {
            width: 80vw;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .list-right {
            margin-top: -15px;
        }

        .right-text {
            display: flex;
            margin-top: 20px;

        }

        .text-details, .text-details1 {
            font-size: 18px;
            overflow: hidden; /*溢出部分隐藏*/
            white-space: nowrap; /*文本不换行*/
            text-overflow: ellipsis; /*当对象内文本溢出时显示省略标记（...）*/
        }

        .thumb {
            height: 200px;
        }
    }

    /*大于1200px*/
    @media only screen and (min-width: 1200px) {
        .box-card {
            width: 45vw;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .list-right {
            margin-top: -15px;
        }

        .right-text {
            display: flex;
            margin-top: 20px;
        }

        .list-left img {
            margin-top: 10px;
        }

        .text-details, .text-details1 {
            font-size: 18px;
            overflow: hidden; /*溢出部分隐藏*/
            white-space: nowrap; /*文本不换行*/
            text-overflow: ellipsis; /*当对象内文本溢出时显示省略标记（...）*/
        }

        .thumb {
            height: 200px;
        }

        text-details study {
            margin-left: 15px;
        }

        .text-details1 {
            margin-left: 15px;
        }
    }

    .right-title {
        font-size: 20px;
        font-weight: 600;
        overflow: hidden; /*溢出部分隐藏*/
        white-space: nowrap; /*文本不换行*/
        text-overflow: ellipsis; /*当对象内文本溢出时显示省略标记（...）*/
    }

    .right-text {
        text-align: left;
    }

    a:hover {
        color: black;
    }

    a:visited {
        color: black;
    }

    a:active {
        color: black;
    }

    a:link {
        color: black;
    }
</style>