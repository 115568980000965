<template>
    <div class="contactUs">
        <div class="contactUs-head">
<!--            联系我们页面title-->
            <el-row class="contactUs-title">联系我们</el-row>
            <el-row>
<!--                左边map-->
                <el-col class="contactUs-map" :lg="{span:12,offset:0}" :md="{span:24,offset:0}">
                    <img src="@/../static/iamges/map.png" style="width: 100%;">
                </el-col>
<!--                右边联系方式-->
                <el-col class="contactUs-information" :lg="{span:7,offset:2}" :md="{span:12,offset:6}"
                        :sm="{span:16,offset:4}">
                    <div class="information-details">

<!--                        循环遍历联系方式-->
                        <el-row class="contactUs-detail" v-for="(item,index) in contactUs" :key="index">
                            <el-col :lg="{span:2,offset:0}" :md="{span:2,offset:0}">
                                <img :src="item.icon" style="width: 35px;height: 35px;margin-top: 5px;color: gray">
                            </el-col>
                            <el-col class="detail-text" :lg="{span:8,offset:1}" :md="{span:8,offset:0}"
                            >{{item.text}}
                            </el-col>
                        </el-row>

                    </div>

<!--                    右边二维码-->
                    <el-row>
                        <el-col class="contactUs-logo">
                            <img src="@/../static/iamges/logo.png">
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>
        </div>


    </div>
</template>

<script>
    export default {
        name: "contactUs",
        data() {
            return {
                contactUs: [
                    {
                        icon: require("@/../static/iamges/phone.png"),
                        text: "18121496883"
                    },
                    {
                        icon: require("@/../static/iamges/location.png"),
                        text: "江苏省苏州市工业园区仁爱路99号D2-410.411"
                    },
                    {
                        icon: require("@/../static/iamges/email.png"),
                        text: "product@medppp.com"
                    },
                    {
                        icon: require("@/../static/iamges/www.png"),
                        text: "www.deepmega.net"
                    },
                ],
            }
        },
        methods: {},
        mounted() {
            //通知父路由自己的索引值
            this.$emit("handleSelect", "6", ["6"])

        }
    }
</script>

<style scoped lang="css">
    /*小于768px时候的样式*/
    @media only screen and (max-width: 768px) {
        .detail-text {
            font-size: 14px;
        }
    }

    /*768px-992px*/
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .contactUs-detail {
            margin-left: 60px;
        }
    }


    /*小于1200*/
    @media only screen and (max-width: 1200px) {
        .contactUs-map {
            width: 100vw;
            margin-top: 50px;
        }

        .contactUs-detail {
            display: flex;
            margin-top: 15px;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

        }

        .contactUs-logo img {
            width: 350px;
            height: 230px;
            margin-top: 40px;
        }

        .contactUs-detail >>> .el-col {
            width: 18%;
        }

        .contactUs-detail >>> .el-row {
            margin-left: -10px;
        }

        .detail-text {
            /*font-size: 14px;*/
            color: black;
            font-weight: 600;
            white-space: nowrap;
            text-align: left;
        }

        .information-details {
            /*box-shadow: 0px 2px 12px 12px rgba(0, 0, 0, 0.1);*/
            /*border-radius: 4px;*/
            /*padding: 10px;*/
            white-space: nowrap;
            /*border: 1px solid #EBEEF5;*/
            text-align: center;
            margin-top: 20px;

        }

        .contactUs-information {
            margin-top: 50px;

        }
    }

    /*大于1200*/
    @media only screen and (min-width: 1200px) {
        .contactUs-map {
            margin-top: 60px;
            margin-left: 50px;
            margin-bottom: 30px;

        }

        .contactUs-detail {
            display: flex;
            align-items: center;
            margin-top: 15px;


        }

        .information-details {
            /*box-shadow: 0px 2px 12px 12px rgba(0, 0, 0, 0.1);*/
            /*border-radius: 10px;*/
            /*padding: 10px;*/
            white-space: nowrap;
            /*border: 1px solid #EBEEF5;*/
            overflow: auto;

        }

        .contactUs-information {
            margin-top: 80px;


        }

        .contactUs-logo img {
            width: 350px;
            height: 210px;
            margin-top: 80px;
        }
    }

    .contactUs-head {
        width: 100%;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.37);
    }

    .contactUs-title {
        font-size: 32px;
        font-weight: 600;
        color: #074A81;
        text-align: center;
        line-height: 90px;
    }

    .detail-text {
        color: black;
        font-weight: 600;
        white-space: nowrap;
        text-align: left;
    }


</style>