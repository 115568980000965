<template>
    <div class="news">
        <div class="conntext">

            <h1 class="title">图灵基因三周岁啦！</h1>
            <p>截至2022年底，
                我们图灵基因公众号完成了累计2100余篇原创的文献解读，累计专业粉丝30755人，累计阅读313万次，我们兼职编辑的团队人数也扩大到接近100人，年中我们对内容的专业性和可阅读性也做了改进，单篇阅读量较前两年有显著提高，在此感谢我们兼职编辑的用心解读和读者粉丝对我们的关注和转发，
                希望我们的文章可以帮助到更多的生物医学领域专业人士。</p>
            <h3>二代扫描仪产品</h3>
            <p>
                2022年，我们图灵基因新一代的数字切片扫描仪完成了多项严苛的电磁兼容、安全等测试；扫描分辨率、稳定性和速度等诸多指标也相较第一代有了显著的提升。同时，我们的病理AI平台可以完成有监督的、无监督的多种机器学习，帮助临床和科研工作者实现训练自己的AI模型，特别是将单细胞多组学和影像学结合，发现更多生命的奇妙结构，推动智慧医疗和精准医疗，并且可以在工作中不断积累和完善，最终可以让更多患者得到更优质的医疗服务。</p>
            <p>我们也在和多家知名三甲医院和研究机构合作，开发更好用的扫描仪产品和细胞/病理组织图像分析软件，欢迎对医学影像和多组学等数据联合分析感兴趣的同仁与我们联系合作！（文末附联系方式）</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n1.jpg"></span>
            <h4>吴江新工厂</h4>
            <p>
                随着2022年底我们吴江新工厂装修完成，我们图灵基因总的生产研发面积又扩大了1300多平米，新工厂拥有生产线、测试实验室、研发办公室、机房、多媒体教室、产品展示、休闲会晤区等。我们将在这里按照我国、欧盟和美国等对相关医疗器械的生产标准要求，投产我们新的实验室、生产、检测流水线等。</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n2.png"></span>
            <p>2023年是图灵基因进阶的一年。</p>
            <p>感谢我们上百位撰稿人，冲破疫情的阴霾，帮助大家拓展认知的边界。</p>
            <p>感谢你们，我们亲爱的粉丝、读者们，你们的关注是我们前行的巨大动力。</p>
            <p>前路虽然坎坷， 但相信只要我们每天进步一点点，偶尔回头一看，发现已经超越了曾经的不可能。</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n3.png"></span>
            <p>“4192年被称为‘奇点之年’，也有人叫它‘起点之年’，仿佛一切重生了一样。”</p>
            <p>“为啥这么说呢，世界在那一年有发生什么重大的变化吗？”</p>
            <p>“变化不是那一年发生的，而是在几十几百年时间里逐步发生的。但到了那一年，大部分的‘原生派’人类族群皈依了‘数字派’，他们的肉身还可以完好地保存数千年，但是他们的绝大部分精神活动都将以数字的形式存在。”</p>
            <p>“我们现在就是数字的存在是吗， 原生就是肉身是吗，听说肉身的人只能经历几十年的时间，而且肉身的人一天只能做我们千分之一的思维活动。”</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n4.png"></span>
            <p>
                “从数字人获得合法身份以来，原生人类的比例一直在下降，现在原生人已经很少能碰到了，偶尔有几个拥有原生人身份的数字人，基本除了亿万富翁大都最后放弃了原生身体的维护，因为维护费用过于昂贵，将来的唤醒费用更是天文数字，关键原生身体比数字身体笨拙太多，就像是放弃超人的身体而转投凡胎，会非常不适应。数字人把思维效率提升了几个数量级以后，普通人数字化后可以找到高薪的工作；画家，作家，音乐家，诗人就再也没有了肉身的束缚，可以去探索远远超过从前人类的新的艺术空间；科学家也不能抵御这样的诱惑，在数字空间可以做比物理世界多的多的实验，而且花费极少。
                一开始还有一些理论和实验物理学家坚持在真实的物理世界探索，但随着国家自然基金会发现这些‘原生派’的产出低到可以忽略不计，也就逐渐减少了对‘原生派’科学家的资金支持。当然基金会里还是有支持‘原生派’的声音，比如说一些科学家坚持认为重大的基础发现只能在真实世界被发现等等，但是我们已经有两千年没有发现新的基础物理定律了，大部分人都倾向于认为我们这个世界可以被认知的物理定律也就我们知道的这些了。”</p>
            <p>“ 我听说过有‘数字原生派’，他们可以综合二者的优势吗？”</p>
            <p>“
                ‘数字原生派’希望利用数字派强大的算力，同时又有‘原生派’深入事物底层的探索，他们一开始相较传统的‘原生派’优势是显著的，所以有段时间几乎挤占了纯粹的‘原生派’的生存空间。但随着量子计算对真实世界的模拟越来越准确，甚至总可以得到比真实世界“更准确”的测量结果，‘数字原生派’存在的意义就越来越遭到质疑。到后面甚至还不如纯粹的‘原生派’，后者还可以从残存的“原生派”信徒那里获得支持。”</p>
            <p>
                上面这段对话可能发生在未来，那时可能绝大部分人类都以数字智能体的形式存在。关于“原生派”和“数字派”的讨论，或许有点科幻，但时代大趋势是越来越多的行业和人类活动正在不可逆转地走向数字化，有的为了提高效率降低成本，有的为了便捷性移动性，有的则为了降低出错率。在所有行业中，生物医学的数字化和智能化最有可能给我们的生活带来革命性影响，因为它涉及到我们自身的存在，繁衍和进化的方式。</p>
            <p>
                我们图灵基因开发的数字切片扫描仪正在推动着病理，细胞生物等学科的数字化和智能化，从某种意义上说，也是“原生派”向“数字派”迁移的时代大背景在生物医学领域的一个体现。也许若干年后，数字切片扫描仪可以替代绝大部分的显微镜，成为医生和科学家桌面上实现与微观世界沟通和发现探索的必备利器，机器学习将借助这样的数字切片扫描仪，帮助科学家完成更多生命微观结构上的科学发现。</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n5.png"></span>
            <p>图灵基因面向科研工作者推出的 TB-1 数字病理切片扫描仪，自带AI建模和模型解释功能，有望帮助您发现更多细胞和组织空间结构规律。</p>
            <p>简单易用，轻松构建原创模型，成本可控，门槛低</p>
            <p>联系微信号：aipathology 电话：18121496883 （刘小姐）</p>
            <span><img style="width: 100%;" src="@/../static/iamges/news/n6.png"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NewsInformation",
        mounted() {
            this.$emit("handleSelect","4",["4"])
        }
    }
</script>

<style scoped>
    /*小于992px*/
    @media only screen and (max-width: 992px) {
        .conntext {
            width: 80vw;
            color: #272e27;
            margin-bottom: 100px;
            line-height: 30px;
        }
    }
/*大于992px*/
    @media only screen and (min-width: 992px) {
        .title {
            font-size: 40px;
            margin-bottom: 50px;
        }
        .conntext {
            width: 40vw;
            color: #272e27;
            margin-bottom: 100px;
            line-height: 30px;
        }
    }

    .news {
        display: flex;
        justify-content: center;
    }

    p {
        white-space: normal;
        text-align: left;
        text-indent: 2em;
        margin-bottom: 30px;

    }
</style>